/* eslint-disable react/prop-types */

import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  productPageBannerSection,
  bigTitle,
  smallTitle,
  productPageBannerImgAlign,
  productPageTitleAlign,
  productPageTitle,
  productPageImage,
  btnPrimary,
  btnPrimaryAlign,
} from '../styles/productPageHero.module.css';

export const fragment = graphql`
  fragment productPageHeroSection on WpPage_Pagetemplate_Sections_HeroProductPage {
    introSmallTitle
    title
    description
    ctaButton {
      buttonText
      buttonUrl
    }
    image {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: DOMINANT_COLOR
            webpOptions: { quality: 70 }
          )
        }
      }
    }
  }
`;

const ProductPageHero = ({
  introSmallTitle,
  title,
  description,
  ctaButton,
  image,
}) => {
  const bannerImage = getImage(image.localFile);

  return (
    <section className={productPageBannerSection}>
      <div className={productPageTitleAlign}>
        {introSmallTitle ? (
          <div className={productPageTitle}>
            <h1 className={smallTitle}>{introSmallTitle}</h1>
            <h2 className={bigTitle}>{title}</h2>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        ) : (
          <div className={productPageTitle}>
            <h1 className={bigTitle}>{title}</h1>

            <div dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        )}
        <div className={btnPrimaryAlign}>
          <Link className={btnPrimary} to={ctaButton.buttonUrl}>
            {ctaButton.buttonText}
          </Link>
        </div>
      </div>
      <div className={productPageBannerImgAlign}>
        <GatsbyImage
          image={bannerImage}
          alt={image.altText}
          className={productPageImage}
          loading="eager"
        />
      </div>
    </section>
  );
};

export default ProductPageHero;
