/* eslint-disable react/prop-types */

import React from 'react';
import { graphql } from 'gatsby';
import {
  teamMemberSection,
  teamMemberSectionTitle,
} from '../styles/teamMember.module.css';
import TeamMemberSingle from './TeamMemberSingle';

export const fragment = graphql`
  fragment teamMemberSection on WpPage_Pagetemplate_Sections_TeamMembersSection {
    title
    members {
      positionRole
      memberName
      memberDescription
      memberLinkedin
      teamMemberImage {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: TRACED_SVG
              webpOptions: { quality: 70 }
              quality: 100
            )
          }
        }
      }
    }
  }
`;

const TeamMember = ({ title, members }) => {
  return (
    <section className={teamMemberSection}>
      <div className={teamMemberSectionTitle}>
        <h2>{title}</h2>
      </div>
      <div>
        {members.map((member, index) => {
          return <TeamMemberSingle key={member.id} index={index} {...member} />;
        })}
      </div>
    </section>
  );
};

export default TeamMember;
