// extracted by mini-css-extract-plugin
export var btnPrimary = "heroVideo-module--btnPrimary--df0c9";
export var btnPrimaryBannerAlign = "heroVideo-module--btnPrimaryBannerAlign--b4df2";
export var btnVideoBanner = "heroVideo-module--btnVideoBanner--eaa95";
export var closeBtnModal = "heroVideo-module--closeBtnModal--1f6bf";
export var heroColumnLeft = "heroVideo-module--heroColumnLeft--705b8";
export var heroColumnRight = "heroVideo-module--heroColumnRight--7c120";
export var heroSection = "heroVideo-module--heroSection--01784";
export var imagePlaceholderStyle = "heroVideo-module--imagePlaceholderStyle--26648";
export var modalAlign = "heroVideo-module--modalAlign--9099d";
export var modalBg = "heroVideo-module--modalBg--41861";
export var modalContent = "heroVideo-module--modalContent--efca0";
export var modalVideo = "heroVideo-module--modal-video--dd3c3";
export var modalVideoAlign = "heroVideo-module--modalVideoAlign--26e4d";
export var modalVideoStyle = "heroVideo-module--modalVideoStyle--8afa9";
export var spin = "heroVideo-module--spin--9c86a";
export var spinnerAlign = "heroVideo-module--spinnerAlign--eb7a4";
export var spinnerStyle = "heroVideo-module--spinnerStyle--345db";
export var videoPlaceholder = "heroVideo-module--videoPlaceholder--d2976";