/* eslint-disable react/prop-types */

import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  teamMemberAlign,
  teamMemberImgAlign,
  teamMemberImg,
  teamMemberInfo,
  teamMemberPosition,
  teamMemberName,
  teamMemberLinkedin,
  memberDescriptionAlign,
} from '../styles/teamMemberSingle.module.css';

const TeamMemberSingle = ({
  positionRole,
  memberName,
  memberDescription,
  memberLinkedin,
  teamMemberImage,
}) => {
  const memberImg = getImage(teamMemberImage.localFile);

  return (
    <div className={teamMemberAlign}>
      <div className={teamMemberImgAlign}>
        <GatsbyImage
          image={memberImg}
          alt={teamMemberImage.altText}
          className={teamMemberImg}
        />
        {memberLinkedin && (
          <a
            href={
              memberLinkedin
                ? memberLinkedin.startsWith('http')
                  ? memberLinkedin
                  : `https://${memberLinkedin}`
                : '#'
            }
            alignSelf={{ base: "center", md: "baseline" }}
            target="_blank"
            rel="noreferrer"
            className={teamMemberLinkedin}
          >
            <img
              src="https://bridgepatientportalcms.nwwtest.com/content/uploads/2024/12/linkedin.png"
              alt="LinkedIn Logo"
            />
          </a>
        )}
      </div>
      <div className={teamMemberInfo}>
        <div className={teamMemberPosition}>
          <span>{positionRole}</span>
        </div>
        <div className={teamMemberName}>
          <h3>{memberName}</h3>
        </div>
        <div
          className={memberDescriptionAlign}
          dangerouslySetInnerHTML={{ __html: memberDescription }}
        />
      </div>
    </div>
  );
};

export default TeamMemberSingle;
