import React from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Section from '../../components/Section/Section';
import ContactForm from '../../components/ContactForm/ContactForm';

export const fragment = graphql`
  fragment contactUsFormSection on WpPage_Pagetemplate_Sections_ContactFormSection {
    contactFormDescription
    contactFormTitle
    subTitle
  }
`;

const ContactUsSection = () => {
  return (
    <Section variant="contact">
      <Container>
        <Row>
          <Col>
            <h2 className="text-center">REQUEST A DEMO</h2>
            <ContactForm />
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default ContactUsSection;
