import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo';
import Section from '../components/Section/Section';
import ContactForm from '../components/ContactForm/ContactForm';

const ViewDemo = () => {
  return (
    <Layout>
      <Seo
        title="Request A BridgeInteract Demo For Your Organization | Bridge"
        description="Learn more about our patient portal for hospitals, large practices, and health systems. Contact us to see a demo."
        canonical="/view-a-demo/"
      />
      <Section variant="hero">
        <Container>
          <Row>
            <Col>
              <h1 className="text-center">REQUEST A DEMO</h1>
            </Col>
          </Row>
          <Row className="justify-content-lg-between">
            <Col lg={{ span: 6, order: 1 }} className="mt-3 mt-lg-n6">
              <ContactForm />
            </Col>
            <Col lg={{ span: 4, order: 0 }} className="mt-3 mt-lg-0">
              <div className="mb-4 mt-4">
                <h4><strong>Our Office</strong></h4>
                <p className="m-0 font-size-sm">
                  Our office is in Dallas, Texas. We are open Monday to Friday
                  from 9:00am - 5:00pm EST.
                </p>
              </div>
              <div className="mb-4">
                <h4><strong>Mailing Address</strong></h4>
                <p className="m-0 font-size-sm">
                  <a
                    href="https://maps.app.goo.gl/DxUq4jC9WSBQ8MYk9"
                    target="_blank"
                    rel="noreferrer"
                  >
                    5950 Sherry Ln, Ste 405B, Dallas, TX 75225
                  </a>
                </p>
              </div>
              <div className="mb-4">
                <h4><strong>Phone</strong></h4>
                <p className="m-0 font-size-sm">
                  Toll-Free:{' '}
                  <strong>
                    <a href="tel:8004672321">800-467-2321</a>
                  </strong>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default ViewDemo;
