/* eslint-disable react/prop-types */

import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  featureTitle,
  featureDescription,
  featureContainer,
  featureImg,
} from '../styles/featuresCol.module.css';
import '../styles/featureIconDisplay.css';

const FeaturesCol = ({ title, description, iconDisplay, icons }) => {
  const icon = getImage(icons?.localFile);

  return (
    <div className={featureContainer}>
      <div className={featureImg}>
        <GatsbyImage
          image={icon}
          alt={icons?.altText}
          className={iconDisplay}
          loading="eager"
        />
      </div>
      <div>
        <div className={featureTitle}>
          <span>{title}</span>
        </div>
        <div
          className={featureDescription}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  );
};

export default FeaturesCol;
