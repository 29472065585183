// extracted by mini-css-extract-plugin
export var btnSecondary = "product-module--btnSecondary--212a8";
export var productDescriptionAlign = "product-module--productDescriptionAlign--8e98a";
export var productDescriptionBackground = "product-module--productDescriptionBackground--7ee6d";
export var productDescriptionBtn = "product-module--productDescriptionBtn--b6043";
export var productDescriptionBulletpoint = "product-module--productDescriptionBulletpoint--6c3e0";
export var productDescriptionLeft = "product-module--productDescriptionLeft--1a260";
export var productDescriptionLeftText = "product-module--productDescriptionLeftText--b9341";
export var productDescriptionRight = "product-module--productDescriptionRight--c5776";
export var productDescriptionRightInternalLink = "product-module--productDescriptionRightInternalLink--62e66";
export var productDescriptionTitle = "product-module--productDescriptionTitle--24762";