exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bridge-partners-js": () => import("./../../../src/pages/bridge-partners.js" /* webpackChunkName: "component---src-pages-bridge-partners-js" */),
  "component---src-pages-health-it-compliance-security-js": () => import("./../../../src/pages/health-it-compliance-security.js" /* webpackChunkName: "component---src-pages-health-it-compliance-security-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-patient-access-js": () => import("./../../../src/pages/patient-access.js" /* webpackChunkName: "component---src-pages-patient-access-js" */),
  "component---src-pages-patient-engagement-platforms-compared-js": () => import("./../../../src/pages/patient-engagement-platforms-compared.js" /* webpackChunkName: "component---src-pages-patient-engagement-platforms-compared-js" */),
  "component---src-pages-patient-engagement-solution-js": () => import("./../../../src/pages/patient-engagement-solution.js" /* webpackChunkName: "component---src-pages-patient-engagement-solution-js" */),
  "component---src-pages-patient-portals-macra-mips-meaningful-use-js": () => import("./../../../src/pages/patient-portals-macra-mips-meaningful-use.js" /* webpackChunkName: "component---src-pages-patient-portals-macra-mips-meaningful-use-js" */),
  "component---src-pages-platform-features-js": () => import("./../../../src/pages/platform-features.js" /* webpackChunkName: "component---src-pages-platform-features-js" */),
  "component---src-pages-service-level-agreement-js": () => import("./../../../src/pages/service-level-agreement.js" /* webpackChunkName: "component---src-pages-service-level-agreement-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-view-a-demo-js": () => import("./../../../src/pages/view-a-demo.js" /* webpackChunkName: "component---src-pages-view-a-demo-js" */),
  "component---src-templates-blog-author-js": () => import("./../../../src/templates/blog-author.js" /* webpackChunkName: "component---src-templates-blog-author-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

